/* The actual timeline (the vertical ruler) */
.main-timeline {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #4793AF;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: #4793AF;
  border: 5px solid #4793AF;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #4793AF; /* Adjusted color */
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent #4793AF transparent transparent; /* Adjusted color */
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Adjust timeline position */
  .main-timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Adjust arrow direction */
  .timeline::before {
    left: 60px;
    /* background-color: #4793AF;  */
    /* border: medium solid white; */
    border-width: 10px 10px 10px 0;
    border-color: transparent #4793AF transparent transparent;
  }

  /* Adjust circle position */
  .left::after,
  .right::after {
    left: 18px;
  }

  /* Adjust arrow direction */
  .left::before {
    right: auto;
  }

  /* Adjust right container */
  .right {
    left: 0%;
  }
}

/* Add a class for card width */
.card-width {
  width: auto;
  max-width: 200%; /* Adjust this value as needed */
  margin: 0 auto;
  transition: transform 0.3s ease; 
  border-color: white;
}

.card-width:hover {
  transform: translate3d(0, -15px, 0); /* Move card further up */
  cursor: pointer;
  border-color: #4793AF;
  box-shadow: 0 0 10px #4793AF;
}
