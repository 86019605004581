.carousel-control-prev-icon {
  /* Change the color */
  color: white;

  /* Change the position */
  left: 25%; /* Adjust as needed */
  margin-left: 200px;
  transform: scale(2.5);
}

.carousel-control-next-icon {
  /* Change the color */
  color: white;

  /* Change the position */
  right: 25%; /* Adjust as needed */
  margin-right: 200px;
  transform: scale(2.5);

  
}
