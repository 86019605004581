body {
  background-color: rgb(23, 23, 88);
   /* Adjust the path to your background image */
  background-repeat: round;
  background-size: 800px 800px;
  background-attachment: fixed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.card {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 576px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}




/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
