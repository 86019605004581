body {
  background-color: rgb(23, 23, 88);
  background-repeat: round;
  background-size: 800px 800px;
  background-attachment: fixed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-text h5 {
  color: #F1B61E;
}

.custom-card {
  width: 100%;
  margin-top: 20px;
  max-width: auto; /* Adjust the max-width as needed */
  transition: none; /* Ensure no transition on the card */
}

.circular-image {
  max-width: 115px; /* Adjust the width as needed */
  max-height: 130px; /* Ensure the height is the same as the width */
  object-fit: cover; /* Cover the entire area */
  margin: 0px auto; /* Center the image horizontally */
  transition: transform 0.3s ease; /* Smooth transition */
}
.circular-image img{
    margin-left: 2px;
    
}
.custom-card:hover .circular-image {
  transform: scale(1.2); /* Increase size by 20% */
}

.custom-card .card-title {
  transition: transform 0.3s ease; /* Smooth transition */
}

.custom-card:hover .card-title {
  transform: translateY(20px); 
  margin-top: 20px;/* Move the title down by 20px */
}

.custom-card:hover .card-text {
  transform: translateY(-10px); 
  /* Move the title down by 20px */
  
}

.card-img-no-border {
  border: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.card-wrapper {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 733px) {
  .custom-card {
    width: 100%;
  }
}
